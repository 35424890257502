<template>
    <div class="login_form">
        <div class="form_title">
            Good morning!<br />
            Welcome back
        </div>
        <form @submit.prevent="onLoginHandler">
            <div class="form_row_wrapper">
                <div class="form_row">
                    <div class="label">
                        Email
                    </div>
                    <input
                        v-model="email"
                        class="form_control"
                        type="email"
                        autocomplete="off"
                        required
                    />
                </div>
                <div class="form_row">
                    <div class="label">
                        Password
                    </div>
                    <input
                        v-model="password"
                        class="form_control"
                        type="password"
                        autocomplete="off"
                        required
                    />
                </div>
            </div>
            <div v-if="error" class="error-wrapper">
                {{ error }}
            </div>
            <div class="form_btn">
                <input class="btn btn_brand" type="submit" value="LOGIN" />
            </div>
        </form>
        <div class="account_control">
            <div class="control_row">
                <nuxt-link to="/forget-password">
                    Forgot your password?
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    layout: 'login',

    data() {
        return {
            email: '',
            password: '',
            error: null
        };
    },

    head() {
        return {
            title: 'Login | Client Portal | Unique Technologies'
        };
    },

    methods: {
        async onLoginHandler() {
            this.error = null;

            try {
                await this.$auth.loginWith('local', {
                    data: {
                        email: this.email,
                        password: this.password
                    }
                });
            } catch (error) {
                console.error(error);

                this.$sentry.captureException(error);

                if (error.response && error.response.status === 401) {
                    this.error = 'Invalid username or password';
                }
            }
        }
    }
};
</script>
